import {
  UpdateEventOccurrenceDescription,
  UpdateEventOccurrencePhysicalLocation,
  UpdateEventOccurrenceVirtualLocation,
  UpdateEventOccurrenceSchedule,
  CancelEventOccurrence,
  UncancelEventOccurrence,
  ManifestEvent
} from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("EventApi", { level: DEBUG });

export async function updateEventOccurrenceDescription(eventOccurrenceId, title, descriptionJson, rsvpLimit) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NUMBER_TOO_SMALL, "RSVP limit can not be negative."],
    [BE.NOT_EXIST, "This event was not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventOccurrenceDescription,
        variables: {
          eventOccurrenceId,
          title,
          descriptionJson,
          rsvpLimit
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventOccurrencePhysicalLocation(eventOccurrenceId, lat, lng, physicalAddress, physicalTitle) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This event was not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventOccurrencePhysicalLocation,
        variables: {
          eventOccurrenceId,
          lat,
          lng,
          physicalAddress,
          physicalTitle
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventOccurrenceVirtualLocation(eventOccurrenceId, lat, lng, virtualUrl, virtualMeetingId, virtualMeetingPassword) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This event was not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventOccurrenceVirtualLocation,
        variables: {
          eventOccurrenceId,
          lat,
          lng,
          virtualUrl,
          virtualMeetingId,
          virtualMeetingPassword
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventOccurrenceSchedule(eventOccurrenceId, startsAt, durationMinutes) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NUMBER_TOO_SMALL, "The start time can't be in the past."],
    [BE.NOT_EXIST, "This event was not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "This event was not found."],
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventOccurrenceSchedule,
        variables: {
          eventOccurrenceId,
          startsAt,
          durationMinutes
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function cancelEventOccurrence(eventOccurrenceId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This event was not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this event."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again.The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CancelEventOccurrence,
        variables: {
          eventOccurrenceId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uncancelEventOccurrence(eventOccurrenceId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event not found."],
    [BE.TOO_LATE, "You cannot reschedule this event past the date of the next event."],
    [BE.PERMISSION, "You don't have permission to update this event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating the event."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UncancelEventOccurrence,
        variables: {
          eventOccurrenceId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function manifestEvent(eventSeriesId, asOfDate) {
  const validationReasonMap = new Map([
    [BE.NOT_PUBLISHED, "Event Series of this event is not yet published."],
    [BE.NOT_EXIST, "Event series of this event was not found."],
    [BE.CANCELLED, "Event series of this event was cancelled."],
    [BE.NOT_BELONG, "Event date does not match occurrence schedule for Event Series"],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error manifesting this event."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ManifestEvent,
        variables: {
          eventSeriesId,
          asOfDate
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
